import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      title: "Dashbord",
      icon: "home",
      badgeType: "light-primary",
      active: true,
      path: "/accueil", type: "link"
    },
    {
      title: "Setting",
      icon: "settings-alt",
      type: "sub",
      badgeType: "light-primary",
      badgeValue: "2",
      children: [
        {
          title: "Zone",
          icon: "",
          type: "sub",
          badgeType: "light-primary",
          badgeValue: "2",
          children: [
            { path: "/parametre/zone", title: "Zones", type: "link" },
            { path: "/parametre/secteur", title: "Secteurs", type: "link" },
          ]
        },
        {
          title: "Stock",
          icon: "",
          type: "sub",
          badgeType: "light-primary",
          badgeValue: "3",
          children: [
            { path: "/stock/depot", title: "Dépôts", type: "link" },
            { path: "/stock/categorie", title: "Catégories", type: "link" },
            { path: "/stock/article", title: "Articles", type: "link" },
          ]
        },
      ],
    },
    {
      title: "Stock/Inventaire",
      icon: "settings-alt",
      type: "sub",
      badgeType: "light-primary",
      badgeValue: "1",
      children: [
        {
          title: "Situation du stock",
          path: "/stock/situation",
          icon: "",
          type: "link",
          badgeType: "light-primary",
        },
        {
          title: "Inventaires",
          path: "/stock/inventaire",
          icon: "",
          type: "link",
          badgeType: "light-primary",
        },
      ],
    },
    {
      title: "Tiers",
      icon: "settings-alt",
      type: "sub",
      badgeType: "light-primary",
      badgeValue: "2",
      children: [
        {
          title: "Client",
          path: "/tiers/client",
          icon: "",
          type: "link",
          badgeType: "light-primary",
        },
        {
          title: "Fournisseur",
          path: "/tiers/fournisseur",
          icon: "",
          type: "link",
          badgeType: "light-primary",
        },
      ],
    },
    {
      title: 'Ventes',
      icon: "settings-alt",
      type: "sub",
      badgeType: "light-primary",
      badgeValue: "2",
      children: [
        {
          title: "Vendeurs",
          path: "/va/vente/vendeurs/def",
          icon: "",
          type: "link",
          badgeType: "light-primary",
        },
        {
          title: "Depots",
          path: "/va/vente/depots/defv",
          icon: "",
          type: "link",
          badgeType: "light-primary",
        },
      ],
    },
    {
      title: 'Achats',
      icon: "settings-alt",
      type: "sub",
      badgeType: "light-primary",
      badgeValue: "2",
      children: [
        {
          title: "Depots",
          path: "/va/achat/depots/defa",
          icon: "",
          type: "link",
          badgeType: "light-primary",
        },
      ],
    },
    {
      title: "Sécurité",
      icon: "settings-alt",
      type: "sub",
      badgeType: "light-primary",
      badgeValue: "2",
      children: [
        {
          title: "Utilisateurs",
          path: "/secu/user",
          icon: "",
          type: "link",
          badgeType: "light-primary",
        },
        {
          title: "Collaborateurs",
          path: "/secu/collaborateur",
          icon: "",
          type: "link",
          badgeType: "light-primary",
        },
      ],
    },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
