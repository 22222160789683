import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ModalDismissReasons, NgbActiveModal, NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import {config} from 'rxjs';
import {content} from '../../shared/routes/routes';


@Component({
  selector: 'ngbd-modal-content',
  standalone: true,
  template: `
    <div class="modal-header">
      <h4 class="modal-title"> <i class="fa fa-exclamation-triangle text-danger"></i></h4>
      <h4 class="modal-title text-danger"> Erreur de création de compte</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      </button>
    </div>
    <div class="modal-body">
      <p class="text-danger">Une erreur s'est produite lors de l' operation. Veuillez réessayer.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark"  (click)="activeModal.close('Close click')">Close</button>
      <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">Réessayer</button>
    </div>
  `
})
export class NgbdModalContent {
  @Input() name;
  constructor(public activeModal: NgbActiveModal) {}
}

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent implements OnInit, OnDestroy {

  constructor(config: NgbModalConfig, private modalService: NgbModal) {
    config.backdrop = 'static';
    config.keyboard = false;

  }

  closeResult: string;

  // tslint:disable-next-line:typedef
  ngOnInit() { }

  ngOnDestroy(): void {
  }



  // tslint:disable-next-line:typedef
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  // tslint:disable-next-line:typedef
  openModal() {
    const modalRef = this.modalService.open(NgbdModalContent);
    modalRef.componentInstance.name = 'World';
  }

  // tslint:disable-next-line:typedef
  openBackDropCustomClass(content) {
    this.modalService.open(content, {backdropClass: 'light-blue-backdrop'});
  }

  // tslint:disable-next-line:typedef
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal' });
  }

  // tslint:disable-next-line:typedef
  openSm(content) {
    this.modalService.open(content, { size: 'sm' });
  }

  openLg(content): void {
    this.modalService.open(content, { size: 'lg' });
  }

  openVerticallyCentered(content): void {
    this.modalService.open(content, { centered: true });
  }

  openStackedModal(): void {
    //   this.modalService.open(NgbdModal1Content);
  }

  openCustomModal(content): void {
    this.modalService.open(content);
  }

}
