import { Component, OnInit } from "@angular/core";
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import {Router, RouterLink} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgbdModalContent} from '../../../../components/modal/modal.component';
import {NgxSpinnerComponent, NgxSpinnerService} from 'ngx-spinner';
import {SharedModule} from '../../../../shared/shared.module';
import {NgStyle} from '@angular/common';

@Component({
  selector: "app-login",
    standalone: true,
    imports: [
        SharedModule,
        RouterLink,
        NgStyle,
        NgxSpinnerComponent
    ],
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: any;

  constructor(private fb: FormBuilder, private spinner: NgxSpinnerService, private authService: AuthService, private modalService: NgbModal, public router: Router) {
    this.initForm();
  }

  initForm() {
    this.loginForm = this.fb.group(
        {
          username: ['', [Validators.required]],
          password: ['', Validators.required],
        }
    );
  }

  ngOnInit() {}

  login() {
    this.spinner.show().then(r => r);
    const username = this.loginForm.get('username')?.value;
    const password = this.loginForm.get('password')?.value;

    // console.log('avant login');
    this.authService.login(username, password).subscribe(
        (value: boolean) => {
            if(this.authService.loggedUser !== null && this.authService.loggedUser.id > 0) {
                this.router.navigate(['/accueil']);
            }else {
                this.spinner.hide().then(r => r);
            }
        },error => {
          this.spinner.hide().then(r => r);
        }
    );
    // console.log('après login');
  }

  showPassword(){
    this.show = !this.show
  }

  openModal() {
    const modalRef = this.modalService.open(NgbdModalContent);
    modalRef.componentInstance.name = 'World';
  }

}
