import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../../applications/securite/services/auth.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

// export const authGuard: CanActivateFn = (route, state) => {
//   return true;
// };


@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean {

    this.authService.isAuthenticated()
        .then((isAuthenticated) => {
          if (isAuthenticated) {
            return true;
          } else {
            this.router.navigate(['/auth/login']);
            return false;
          }
        });

    return true;

  }

  // canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
  //   // Guard for user is login or not
  //   let user = JSON.parse(localStorage.getItem("user"));
  //   if (!user || user === null) {
  //     this.router.navigate(["/auth/login"]);
  //     return true;
  //   } else if (user) {
  //     if (!Object.keys(user).length) {
  //       this.router.navigate(["/auth/login"]);
  //       return true;
  //     }
  //   }
  //   return true;
  // }


}
