export class User {
    id: number;
    username: string;
    nom: string;
    prenoms: string;
    email: string;
    // password: string;
    co_no: number;
    profil: string;
    // token: string = '';

}

export class AuthResponse {
    code: number;
    message: string;
    status: string;
    authorisation: {
        token: string;
        type: string;
    };
    user: User;
}

export class Login {
    username: string;
    password: string;
}
