<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card login-dark">
        <div>
          <div>
            <a class="logo" routerLink="/">
              <img class="img-fluid for-light" src="../../../../../assets/images/logo/logo.png " width="500" alt="looginpage" />
              <img class="img-fluid for-dark" src="../../../../../assets/images/logo/logo_dark.png" width="500" alt="looginpage" />
            </a>
          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm">
              <h4>Connectez-vous</h4>
              <p>Veuillez saisir vos paramètres de connexion</p>
              <div class="form-group">
                <label class="col-form-label">Nom utilisateur</label>
                <input class="form-control" type="email" required="" placeholder="username" formControlName="username" />
                <div *ngIf="loginForm.controls.username.touched && loginForm.controls.username.errors?.required" class="text text-danger mt-1">Nom utilisateur est requis</div>
<!--                <div *ngIf="loginForm.controls.username.touched && loginForm.controls.username.errors?.email" class="text text-danger mt-1">nom utilisateur invalide</div>-->
              </div>
              <div class="form-group">
                <label class="col-form-label">Mot de passe</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="*********" />
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required" class="text text-danger mt-1">Password is required</div>
              </div>
              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox" />
                  <label class="text-muted" for="checkbox1">Se souvenir de moi</label>
                </div>
                <a href="javascript:void(0)" class="link">Forgot password?</a>
                <div class="text-end mt-3">
                  <button class="btn btn-primary d-block w-100" [disabled]="!loginForm.valid" (click)="login()" type="button"><span>Sign in</span></button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
