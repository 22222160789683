import { Routes } from "@angular/router";
import {AuthGuard} from '../guard/auth.guard';


export const content: Routes = [
  {
    path: "",
    loadChildren: () => import("../../applications/securite/securite.module").then((m) => m.SecuriteModule),
  },
  {
    path: "accueil",
    loadChildren: () => import("../../applications/dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "simple-page",
    loadChildren: () => import("../../components/simple-page/simple-page.module").then((m) => m.SimplePageModule),
  },
  {
    path: "parametre",
    loadChildren: () => import("../../applications/parametre/parametre.module").then((m) => m.ParametreModule),
  },
  {
    path: "stock",
    loadChildren: () => import("../../applications/stock/stock.module").then((m) => m.StockModule),
  },
  {
    path: "secu",
    loadChildren: () => import("../../applications/securite/securite.module").then((m) => m.SecuriteModule),
  },
  {
    path: "tiers",
    loadChildren: () => import("../../applications/tiers/tiers.module").then((m) => m.TiersModule),
  },
  {
    path: "va",
    loadChildren: () => import("../../applications/venteachat/venteachat.module").then((m) => m.VenteachatModule),
  },
  {
    path: "single-page",
    loadChildren: () => import("../../components/single-page/single-page.module").then((m) => m.SinglePageModule),
  },
];

