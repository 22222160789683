import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, map, Observable, Subject} from 'rxjs';
import {AuthResponse, User} from '../models/auth.model';
import {Router} from '@angular/router';


const TOKEN_KEY = 'auth-token'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  server: string = environment.globals.accessServer;
  apiUrl: string = environment.globals.urlApi;

  // private loggedIn = false;
  token: String = '';

  loggedUser: User = new User();

  constructor(private http: HttpClient, private router: Router) {
    // this.loggedIn = this.isAuthenticated();
  }

  login(username: string, password: string): Observable<boolean> {

    // this.loggedIn = false;
    var loggedIn$ = new Subject<boolean>();

    // Logique d'authentification

    this.http.post<any>(
        this.apiUrl + 'auth/login',
        {'username':username,'password':password},
        {headers: this.headers})
        .pipe(
          map(
              (response) => ({...response})
          )
        ).subscribe(
          {
          next: (response) => {
            console.log('Réponse de l\'apisage');
            console.log(response);

            let responseGeneral = new AuthResponse();
            responseGeneral = response;

            if(responseGeneral.status == 'success' ) {
              this.loggedUser = responseGeneral.user;

              if(responseGeneral.authorisation.token != '') {
                this.saveToken(responseGeneral.authorisation.token);
                console.log('responseGeneral.authorisation.token => ',responseGeneral.authorisation.token);
                loggedIn$.next(true);

                console.log('mon user => ');
                console.log(this.loggedUser);

              }else {
                loggedIn$.next(false);
              }
            }else {
              loggedIn$.next(false);
            }

          },
          error: (error) => {
            console.log("Erreur d'authentification : " + error);
            loggedIn$.next(false);
          }
        });

    return loggedIn$

  }

  public saveToken(token: string): void {
    console.log("Ecriture token => ",token);
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    this.token = window.sessionStorage.getItem(TOKEN_KEY);
    // console.log('récupération token => ',this.token)
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  get headers() {

    // var valueToken = '';
    // if(this.getToken() !== null) {
    //   valueToken = this.getToken();
    // }
    // console.log("Mon token",valueToken);
    //
    // return new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': `Bearer ${this.getToken()}`
    // });

    return {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Access-Control-Allow-Origin':this.server,
      'Authorization': `Bearer ${this.getToken()}`
    };
  }

  isAuthenticated(): Promise<boolean> {

    return new Promise(
        (resolve, reject) => {
          const token = this.getToken();
          if(!token) {
            resolve(false);
            return;
          }

          this.refreshToken().subscribe(
              {
                next: (value) => {
                  if(value) {
                    resolve(true);
                  }else {
                    resolve(false);
                  }
                },
                error: (error) => {
                  console.error('Authentication error:', error);
                  reject(error);
                }
              }
          );

        }
    )

  }

  refreshToken():Observable<boolean> {

    var loggedIn$ = new Subject<boolean>();

    this.http.get<any>(
        this.apiUrl + 'auth/refresh',
        {headers: this.headers})
        .pipe(
            map(
                (response) => ({...response})
            )
        ).subscribe(
        {
          next: (response) => {
            console.log('Réponse refresh de l\'apisage');
            console.log(response);

            let responseGeneral = new AuthResponse();
            responseGeneral = response;

            if(responseGeneral.status == 'success' ) {
              this.loggedUser = responseGeneral.user;

              if(responseGeneral.authorisation.token != '') {
                this.saveToken(responseGeneral.authorisation.token);
                loggedIn$.next(true);

                console.log('mon user => ');
                console.log(this.loggedUser);

              }else {
                loggedIn$.next(false);
              }
            }else {
              loggedIn$.next(false);
            }
          },
          error: (error) => {
            console.log("Erreur d'authentification : ",error);
            loggedIn$.next(false);
          }
        }
    );

    return loggedIn$
  }

  logout(): void {
    window.sessionStorage.clear();
    this.loggedUser = new User();

  }

  get user() {
    this.isAuthenticated();
    return this.loggedUser;
  }

}
